@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
  font-family: "Montserrat-ExtraBold";
  src: local("Montserrat-ExtraBold"),
    url("./fonts/montserrat/static/Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: bold;
} */

main {
  counter-reset: section;
}

.montserrat-extrabold {
  font-family: "Montserrat-ExtraBold", serif;
}

.middle {
  display: flex;
  justify-content: center;
  align-items: center;
}

button {
  justify-content: center;
  text-align: center;
}

.projects-button {
  @apply bg-transparent m-1 h-14 text-white font-semibold py-2 px-4;
  border-bottom: 2px solid white;
}

.projects-button:hover {
  border-bottom: 3px solid blue;
  transform: scale(1.1);
  transition: 300ms;
  @apply bg-slate-700;
}

.react-icons {
  vertical-align: middle;
}

/* .project-url {
  @apply m-3 place-self-end grid-cols-2;
  display: grid;
  flex-shrink: 0;
} */

.icon:hover {
  transform: scale(1.5);
  transition: 300ms;
}

.resume {
  /* display: flex;
  position: absolute;
  right: 5vw;
  bottom: 80vh; */
  color: white;
}

.sidebar {
  position: fixed;
  right: 1%;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.abs-vert-align {
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.title-section {
  height: 100vh;
  justify-content: center;
  align-items: flex-start;
  color: white;
  display: flex;
}

.content-section {
  position: relative;
  height: fit-content;
  justify-content: center;
  align-items: flex-start;
  color: white;
  display: flex;
  padding-bottom: 3rem;
}

.content-section h1::before {
  counter-increment: section; /* Increment the value of section counter by 1 */
  content: "0" counter(section) ". "; /* Display counter value in default style (decimal) */
  font-size: 2rem;
  color: lightblue;
}

.content-section h3 {
  font-size: 1.5rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: lightsteelblue;
}

.content-section h1 {
  font-weight: 600;
}

.content-section p {
  font-size: 1.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  text-align: justify;
}

.framework-list {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  position: relative;
}

.framework-list li {
  display: flex;
  font-size: 1.5rem;
  font-family: "Roboto", sans-serif;
}

.title-image {
  background-image: url("assets/img/green_loch.jpg");
  box-shadow: inset 0 -200px 200px -100px rgba(0, 0, 0, 0.9);
}

.timeline {
  align-items: center;
  justify-content: center;
}

.intro-text {
  position: absolute;
  right: 50%;
  left: 0;
  padding-left: 5%;
  bottom: 25%;
}

.v-width {
  width: 100vw;
}

@media (max-width: 1023px) {
  .framework-list {
    grid-template-columns: repeat(2, 1fr);
  }
  .projects-buttons {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
  }
  .projects-card {
    display: grid;
    position: relative;
    grid-template-rows: 4rem 1fr;
    scroll-snap-type: y mandatory;
  }
  .content-section p {
    font-size: 1rem;
  }
  .scroll {
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .link-icon {
    width: 15px;
    height: 15px;
  }
  p {
    font-size: 0.9rem;
    /* text-align: justify; */
  }
  h1 {
    font-size: 1.5rem;
    font-family: "Montserrat", sans-serif;
    color: lightskyblue;
  }
  h2 {
    font-size: 1rem;
    font-family: "Montserrat", sans-serif;
  }
  .icon-size {
    width: 30px;
    height: 30px;
  }
  .content-section h1::before {
    font-size: 1rem;
  }
  .content-section h1 {
    font-size: 2rem;
  }
}

@media (min-width: 1024px) {
  .projects-buttons {
    display: flex;
    flex-direction: column;
    overflow-x: scroll;
  }
  .projects-card {
    display: grid;
    position: relative;
    grid-template-columns: 10rem 1fr;
  }
  .link-icon {
    width: 25px;
    height: 25px;
  }
  h1 {
    font-size: 5rem;
    font-family: "Montserrat", sans-serif;
    color: lightskyblue;
    /* @apply text-blue-300; */
  }
  h2 {
    font-size: 2rem;
    font-family: "Montserrat", sans-serif;
  }
  p {
    font-size: 1.5rem;
  }
  .icon-size {
    width: 50px;
    height: 50px;
  }
}
