:root {
    --cursor-colour: white;
    --cursor-time: 750ms;
    --typewriter-time: 2s;
    --steps: 20;
}

.type-name {
    position: relative;
    float: left;
    overflow: hidden;
    margin: 0 auto;
    white-space: nowrap;
    letter-spacing: .02em;
    color: var(--cursor-colour);
    border-right: .1em solid var(--cursor-colour);
    line-height: 1em;
    width: fit-content;

    animation: typewriter var(--typewriter-time) steps(var(--steps)) forwards, cursor var(--cursor-time) steps(var(--steps)) infinite;
}

@keyframes typewriter {
    from {
        width: 0%;
    }
    to {
        width: 100%;
    }
}

@keyframes cursor {
    from, to {
        border-right-color: transparent;
    } 50% {
        border-right-color: var(--cursor-colour);
    }
}
